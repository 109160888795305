import React from "react";

export const Circle = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Middle-School"
          transform="translate(-497.000000, -4043.000000)"
          fill={color}
          fillOpacity={opacity}
          stroke="var(--black)"
        >
          <g id="Blue-Grouping" transform="translate(-1.000000, 3022.000000)">
            <g
              id="Blue-Grouping-Content"
              transform="translate(113.000000, 137.000000)"
            >
              <g
                id="Season-Choices"
                transform="translate(13.335570, 332.890089)"
              >
                <g id="Carousel" transform="translate(0.000000, 54.567123)">
                  <g
                    id="Image-Selection"
                    transform="translate(315.907934, 493.786797)"
                  >
                    <circle
                      id="Oval"
                      cx="63.5920657"
                      cy="10.6066017"
                      r="7"
                    ></circle>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
