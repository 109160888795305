import { Button } from "components/button/Button.component";
import { Caret } from "components/svg-wrapper/components/shapes/Caret.svg";
import { Circle } from "components/svg-wrapper/components/shapes/Circle.svg";
import React from "react";
import "./Pagination.styles.scss";

export const Pagination = ({
  firstIndex,
  lastIndex,
  currentImageIndex,
  setCurrentImageIndex,
}) => {
  const renderButtons = () => {
    const buttons = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
      buttons.push(
        <Button
          className="minimal-padding no-shadow-or-bg-color"
          onClick={() => {
            setCurrentImageIndex(i);
          }}
        >
          <Circle
            width="0.88rem"
            color={
              i === currentImageIndex ? "var(--secondary)" : "var(--white)"
            }
          />
        </Button>
      );
    }
    return buttons;
  };

  const caretProps = {
    width: "0.7rem",
    strokeWidth: "4",
    color: "var(--black)",
  };

  return (
    <div className="pagination-container">
      <Button
        className="minimal-padding no-shadow-or-bg-color"
        disabled={currentImageIndex <= firstIndex}
        onClick={() => {
          setCurrentImageIndex(currentImageIndex - 1);
        }}
      >
        <Caret {...caretProps} style={{ transform: "rotate(180deg)" }} />
      </Button>
      {renderButtons()}
      <Button
        className="minimal-padding no-shadow-or-bg-color"
        disabled={currentImageIndex >= lastIndex}
        onClick={() => {
          setCurrentImageIndex(currentImageIndex + 1);
        }}
      >
        <Caret {...caretProps} />
      </Button>
    </div>
  );
};
