import React from "react";

export const CoreValues = () => {
  return (
    <div>
      <h2>Athletics</h2>
      <h4 className="no-margin">Core Values</h4>
      <ul className="enlarged-list-size">
        <li>Always honor Christ</li>
        <li>Encourage student-athletes to have fun</li>
        <li>Foster the value of participation</li>
        <li>Develop the whole person: body, spirit, mind</li>
      </ul>
    </div>
  );
};
