import React from "react";
import { ZigZagRow } from "./components/zig-zag-row/ZigZagRow.component";
import "./SloganZigZag.styles.scss";

export const SloganZigZag = ({ slogans, shouldInvertColor, style }) => {
  return (
    <div className="articles-wrapper" style={style}>
      {slogans.map((slogan, index) => {
        return (
          <ZigZagRow
            key={slogan.heading}
            slogan={slogan}
            shouldInvertColor={shouldInvertColor}
            index={index}
          />
        );
      })}
    </div>
  );
};
