import { Button } from "components/button/Button.component";
import React from "react";
import "./Tab.styles.scss";

export const Tab = ({ season, index, seasonIndex, setSeasonIndex }) => {
  return (
    <Button
      key={season.label}
      className="tab minimal-padding"
      onClick={() => {
        setSeasonIndex(index);
      }}
      style={{
        backgroundColor:
          seasonIndex === index ? "var(--black)" : "var(--white)",
        color: seasonIndex === index ? "var(--white)" : "var(--teal)",
      }}
      hoverAnimation
      hoverTrigger={seasonIndex === index}
    >
      {season.label}
    </Button>
  );
};
