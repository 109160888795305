import React from "react";

export const Tabs = ({ children }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "-2.22rem",
        left: "0.83rem",
        display: "inherit",
        flexDirection: "row",
      }}
    >
      {children}
    </div>
  );
};
