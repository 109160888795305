import React from "react";

export const Caret = ({
  width,
  height,
  color,
  opacity = "1",
  style,
  strokeWidth,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      viewBox="0 0 14 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Components/Accordion-Row"
          transform="translate(-25.000000, -14.000000)"
          stroke={color}
          strokeOpacity={opacity}
          strokeWidth={strokeWidth}
        >
          <g id="Content">
            <polyline
              id="Path-7"
              transform="translate(26.500000, 25.500000) rotate(45.000000) translate(-26.500000, -25.500000) "
              points="19 18 34 18 34 33"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};
