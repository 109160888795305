import React, { useRef } from "react";
import { useEffect } from "react";
import "./Image.styles.scss";

export const Image = ({ image, index, currentImageIndex, setRef }) => {
  const imageRef = useRef(<div />);

  useEffect(() => {
    if (index === currentImageIndex) {
      setRef(imageRef);
    }
  }, [currentImageIndex]);

  return (
    <div className="image-class">
      <img ref={imageRef} src={image} className="image-class" />
    </div>
  );
};
