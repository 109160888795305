import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./ZigZagRow.styles.scss";

export const ZigZagRow = ({ slogan, shouldInvertColor, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.075,
  });

  const translateLength = 7.5;
  const invertSign = (translateLength, condition) => {
    const invertedValue = !condition ? translateLength * -1 : translateLength;
    return invertedValue;
  };

  const articleVariants = {
    hidden: {
      opacity: 0,
      transform: `translateX(${invertSign(translateLength, index % 2 === 0)}%)`,
    },
    visible: {
      opacity: 1,
      transform: "translateX(0%)",
      transition: {
        duration: 0.6,
      },
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const invertColorLogic = (colorLogic) =>
    shouldInvertColor ? !colorLogic : colorLogic;

  return (
    <div
      style={{
        backgroundColor: invertColorLogic(index % 2 === 0)
          ? "var(--white)"
          : "rgba(var(--tealRGB), 0.075)",
      }}
    >
      <motion.article
        ref={ref}
        variants={articleVariants}
        initial="hidden"
        animate={controls}
        className="article layout"
      >
        <div className="text-aside" style={{ gridColumn: index % 1 }}>
          <h3 className="slogan-heading">{slogan.heading}</h3>
          <ul
            className="enlarged-list-size"
            style={{
              listStyleType: slogan.points.length > 1 ? "initial" : "none",
            }}
          >
            {slogan.points.map((point) => (
              <li key={point} style={{ maxWidth: "40rem" }}>
                {point}
              </li>
            ))}
          </ul>
        </div>
        <img
          className="image-aside"
          style={{ gridColumn: index % 2 }}
          src={slogan.image.src}
          alt={slogan.image.alt}
        />
      </motion.article>
    </div>
  );
};
