import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useEffect, useRef, useState } from "react";
import "./Carousel.styles.scss";
import { Image } from "./components/image/Image.component";
import { Pagination } from "./components/pagination/Pagination.component";
import { Tab } from "./components/tab/Tab.component";
import { Tabs } from "./components/tabs/Tabs.component";

export const Carousel = ({ seasons, seasonIndex, setSeasonIndex }) => {
  const carouselRef = useRef(null);
  const windowWidth = useWindowWidth();
  const [currentImageRef, setCurrentImageRef] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const calculateFirstIndex = (seasonIndex) => {
    let firstIndex = 0;
    let index = 0;
    while (index < seasonIndex) {
      firstIndex += seasons[index].images.length;
      index++;
    }
    return firstIndex;
  };
  const currentArrayLength = seasons[seasonIndex].images.length;
  const currentArrayFirstIndex = calculateFirstIndex(seasonIndex);
  const currentArrayLastIndex = currentArrayFirstIndex + currentArrayLength - 1;

  useEffect(() => {
    if (currentImageRef) {
      carouselRef.current.scrollTo({
        top: 0,
        left: currentImageRef.current.offsetLeft,
      });
    }
  }, [currentImageRef, windowWidth]);

  useEffect(() => {
    setCurrentImageIndex(currentArrayFirstIndex);
  }, [seasonIndex]);

  return (
    <div className="carousel">
      <Tabs>
        {seasons.map((season, index) => {
          return (
            <Tab
              season={season}
              index={index}
              seasonIndex={seasonIndex}
              setSeasonIndex={setSeasonIndex}
            />
          );
        })}
      </Tabs>
      <div
        ref={carouselRef}
        className="carousel-image-wrapper"
        style={{
          backgroundColor: "var(--primary)",
          boxShadow: "var(--level-2)",
        }}
      >
        {seasons
          .map((season) => season.images)
          .reduce((prev, cur) => {
            return prev.concat(cur);
          })
          .map((image, index) => {
            return (
              <Image
                image={image}
                index={index}
                currentImageIndex={currentImageIndex}
                setRef={setCurrentImageRef}
              />
            );
          })}
      </div>
      <Pagination
        firstIndex={currentArrayFirstIndex}
        lastIndex={currentArrayLastIndex}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
      />
    </div>
  );
};
